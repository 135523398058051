<template>
  <loading v-if="isLoading" />
  <div v-else class="board-list">
    <div class="show-course-boards" v-if="boards.length">
      <card v-for="board in boards" :key="board.id" :board="board" />
    </div>
    <h2 v-else class="no-data-msg">
      {{ $t("g.there_is_no_boards") }}
    </h2>
  </div>
</template>

<script>
import Card from "@/components/classrooms/boards/showCourses/boards/layout/card.vue";
import Loading from "@/components/loading/loading.vue";

export default {
  name: "BoardList",
  components: {
    Card,
    Loading,
  },
  data() {
    return {
      boards: null,
      isLoading: true,
    };
  },
  created() {
    this.$http
      .get(`student/courses/${this.$route.params.id}?enrolled=true`)
      .then((res) => {
        if (res.status === 200) {
          this.boards = res.data.data.boards;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style lang="scss" src="./_boardList.scss"></style>
